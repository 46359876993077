#banner-section {
  background-color: $theme-grey;
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: calc(100vh - 280px);
  justify-content: center;

  @media screen and (max-width: 1260px) {
    min-height: calc(50vh - 150px);
  }
  @media (min-width: 769px) and(max-width:990px) {
    padding-top: 20px;
  }
  @media screen and (max-width: $md-device) {
    min-height: calc(60vh - 150px);
  }
  @media screen and (min-width: $md-device) {
    padding-top: 200px;
  }

  .main-banner-content {
    position: relative;
    max-width: 530px;
    margin-left: auto;
    text-align: left;
    @media screen and (max-width: 1260px) {
      max-width: 540px;
    }
    @media screen and (max-width: 1199px) {
      max-width: 445px;
    }
    @media screen and (max-width: 1024px) {
      max-width: 445px;
    }

    @media (min-width: 769px) and(max-width:990px) {
      max-width: 328px;
    }
    @media screen and (max-width: $md-device) {
      padding: 0 0 0 38px;
      max-width: 100%;
    }
    @media screen and (max-width: $sm-device) {
      padding: 0 15px;
      max-width: 100%;
    }
    h1 {
      margin: 30px 0;
      @media (min-width: 769px) and(max-width:990px) {
        margin: 10px 0;
      }
      @media screen and (max-width: $md-device) {
        margin: 20px 0;
      }
    }
    .btn-theme-grey {
      margin-left: 20px;
    }
    .banner-icons {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      li {
        padding: 0 5px;
        img {
          width: 35px;
        }
      }
    }
  }
  .main-banner-image-foreground-desktop {
    display: block;
    max-width: 1000px;
    margin-top: -5%;
    img {
      position: relative;
      z-index: 10;
    }

    @media screen and (max-width: $md-device) {
      display: none;
    }
  }
  .main-banner-image {
    position: absolute;
    top: -40vh;
    right: 0;
    z-index: 1;
    overflow-x: hidden;

    img {
      max-height: 100vh;
    }

    // @media screen and (max-width: 1766px) {
    //   top: -58vh;
    // }
    // @media screen and (max-width: 1366px) {
    //   top: -32vh;
    // }
    // @media screen and (max-width: 1260px) {
    //   top: -40vh;
    // }
    // @media screen and (max-width: 1024px) {
    //   top: -42vh;
    // }
    // @media (min-width: 769px) and(max-width:990px) {
    //   top: -21vh;
    // }

    @media screen and (max-width: $md-device) {
      display: none;
    }
  }

  .main-banner-mobile-image {
    display: none;
    @media screen and (max-width: $md-device) {
      display: block;
    }
  }
}

#built-card-section {
  padding: 60px 0;
  padding-top: 0;
  position: relative;
  background-color: $theme-grey;
  .container {
    position: relative;
    z-index: 100;
  }
  .btn-theme-grey {
    margin-left: 20px;
  }
  .card-built {
    border: 0px;
    border-radius: 0px;
    background: transparent;
    margin-top: 60px;
    @media screen and (max-width: $md-device) {
      margin-top: 40px;
    }
  }
  .logo-image {
    vertical-align: middle;
    height: 40px;
    width: auto;
    object-fit: contain;
    margin-top: 15px;
  }
  &:after {
    content: "";
    width: 100%;
    z-index: 1;
    height: 150px;
    position: absolute;
    background: inherit;
    right: 0px;
    left: 0px;
    bottom: 0px;
    transform-origin: left bottom;
    transform: skewY(2deg);
  }
}
#upcoming-section {
  padding: 180px 0 60px 0;
  background: $theme-footer-dark;
  position: relative;

  @media screen and (max-width: $md-device) {
    padding: 100px 0;
  }

  &:after {
    content: "";
    width: 100%;
    height: 150px;
    position: absolute;
    background: inherit;
    z-index: 1;
    right: 0px;
    left: 0px;
    bottom: 0;
    transform-origin: left bottom;
    transform: skewY(2deg);
  }

  .upcoming-bottom-card {
    margin-top: 40px;
    padding: 15px;
    background: #e7e3fa;
    right: 0px;
    left: 0px;
    transform: skew(-10deg, 1deg);
    border-radius: 10px;

    @media screen and (max-width: $md-device) {
      transform: skew(-9deg, 0deg);
    }

    .card-body {
      transform: skew(10deg, 0deg);
      padding: 30px 20px 10px;
      @media screen and (max-width: $md-device) {
        transform: skew(9deg, 0deg);
      }
      .upcoming-star-icon {
        position: absolute;
        top: -99px;
        left: -2px;
        @media screen and (max-width: $md-device) {
          top: -95px;
        }
      }
    }
  }
  .tab-content {
    text-align: left;
    @media screen and (max-width: $md-device) {
      text-align: center;
    }
  }
  .nav-pills {
    margin-top: 70px;
    @media screen and (max-width: 1024px) {
      margin-top: 30px;
    }
    @media screen and (max-width: 992px) {
      margin-top: 0px;
    }
    @media screen and (max-width: $sm-device) {
      margin-top: 20px;
    }
    .nav-link {
      padding: 0;
      border-radius: 10px;
      .upcoming-card {
        background: transparent !important;
        border: none;
        border-radius: 10px;
        margin-bottom: 15px;
        text-align: left;

        @media screen and (max-width: $md-device) {
          margin-top: 15px;
        }

        .text-bold {
          font-family: "CircularStd-Bold";
        }
        p {
          color: $theme-white;
          margin-bottom: 5px;
        }
      }
      &:focus,
      &:active {
        background-color: $theme-white;
        border: none;
        outline: none;
        .upcoming-card {
          p {
            color: $theme-black;
          }
        }
      }
    }
    .nav-link.active {
      background-color: #fff;
      .upcoming-card {
        .tip {
          width: 0px;
          height: 0px;
          position: absolute;
          background: transparent;
          border: 10px solid #fff;
        }
        .tip-left {
          top: 38%;
          left: -20px;
          border-top-color: transparent;
          border-left-color: transparent;
          border-bottom-color: transparent;
          @media screen and (max-width: $sm-device) {
            display: none;
          }
        }
        p {
          color: $theme-black;
        }
      }
    }
    .show {
      > .nav-link {
        background-color: #fff;
        padding: 0;
        border-radius: 10px;
        .upcoming-card {
          p {
            color: $theme-black;
          }
        }
      }
    }
  }
}
#pricing-section .price-box-theme .pricebtngrey {
  color: #6b39fe !important;
}

#pricing-section {
  padding: 180px 0 60px;
  background: #f6f7fb;
  position: relative;

  @media screen and (max-width: $md-device) {
    padding: 100px 0;
  }

  &:after {
    content: "";
    width: 100%;
    z-index: 1;
    height: 75px;
    position: absolute;
    background: inherit;
    right: 0px;
    left: 0px;
    bottom: 0px;
    transform-origin: left bottom;
    transform: skewY(2deg);
  }

  .price-box {
    @media screen and (max-width: $md-device) {
      display: none;
    }
    background: transparent;
    border: none;
    padding: 40px;
    margin-top: 40px;

    .card-body {
      padding: 0;

      h1 {
        margin-bottom: 10px;
      }
      @media screen and (max-width: $md-device) {
        transform: rotate(-1deg);
      }
      p {
        color: #778288;
      }
    }

    .pricetoplist {
      padding-left: 40px;
      text-align: left;

      .list-group-item {
        padding: 0;
        background: transparent;
        border: none;
        font-weight: 500;
        font-size: 18px;
        color: #6b787e;
      }
      .list-group-item.disabled,
      .list-group-item:disabled {
        color: #b6bdc2;
      }
      .greencheck {
        &:before {
          content: url("../../../images/white-tick-green-circle.png");
          position: absolute;
          left: -24px;
          top: 3px;
        }
      }
    }
    .featurelist {
      text-align: left;

      .list-group-item {
        background: transparent;
        border: none;
        display: flex;
        padding: 0.75rem 1rem;

        &:before {
          content: url("../../../images/green-tick.png");
          position: relative;
          right: 8px;
        }
      }
    }

    .pricebtngrey {
      width: 100%;
      background-color: #6b39fe;
      color: white;
    }
    .pricebtngrey:hover {
      background-color: #e8e4fc;
      color: #6b39fe;
    }

    @media screen and (max-width: $md-device) {
      background: #fff;
      z-index: 1;
      right: 0px;
      left: 0px;
      transform-origin: left bottom;
      transform: skewY(1deg);
      border-radius: 10px;
    }
  }

  .price-box-theme {
    background: #6b39fe;
    z-index: 1;
    right: 0px;
    left: 0px;
    transform-origin: left bottom;
    transform: skewY(1deg);
    border-radius: 10px;

    .card-body {
      transform: rotate(-1deg);

      h1 {
        color: #fff;
      }
      p {
        color: #fff;
      }
      .pricetoplist {
        .list-group-item {
          color: #c4b1fe;
        }

        .greencheck {
          color: #fff;
        }
      }
      .featurelist {
        .list-group-item {
          color: #fff;

          &:before {
            content: url("../../../images/white-tick.png");
            position: relative;
            right: 8px;
          }
        }
      }

      .pricebtngrey {
        background: #fff;
        &:hover,
        &:focus {
          background-color: #e8e4fc;
        }
      }
    }
  }
}
#faq-section {
  background-color: $theme-faq-grey;
  padding: 100px 0 60px 0;
  @media screen and (max-width: $md-device) {
    padding: 100px 0 0;
  }

  .faq-card {
    border: none;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    background: #fff;
    box-shadow: 1px 2px 5px #ece8e8;
    text-align: left;
    cursor: pointer;

    @media screen and (max-width: $md-device) {
      padding: 0px;
    }

    .card-title {
      font-weight: 600;
      font-family: "CircularStd-Book";
    }
    .card-text {
      color: $theme-grey-text;
    }
  }
  // .faq-card:hover {
  //   // background-color: #6b39fe;
  //   // transition: 0.5s;
  //   .card-text {
  //     color: white;
  //   }
  //   .card-title {
  //     color: white !important;
  //   }
  // }
  .faq-footer {
    position: relative;
    bottom: -210px;
    z-index: 1;
    @media screen and (max-width: $sm-device) {
      bottom: -65px;
    }
  }
}

.built-by-line {
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 1px;
}

.high-z-index {
  z-index: 20;
  position: relative;
}

.price-boxMobile {
  @media screen and (max-width: $md-device) {
    display: block;
  }
  background: transparent;
  border: none;
  padding: 40px;
  margin-top: 40px;

  .card-body {
    padding: 0;

    h1 {
      margin-bottom: 10px;
    }
    @media screen and (max-width: $md-device) {
      transform: rotate(-1deg);
    }
    p {
      color: #778288;
    }
  }

  .pricetoplist {
    padding-left: 40px;
    text-align: left;

    .list-group-item {
      padding: 0;
      background: transparent;
      border: none;
      font-weight: 500;
      font-size: 18px;
      color: #6b787e;
    }
    .list-group-item.disabled,
    .list-group-item:disabled {
      color: #b6bdc2;
    }
    .greencheck {
      &:before {
        content: url("../../../images/white-tick-green-circle.png");
        position: absolute;
        left: -24px;
        top: 3px;
      }
    }
  }
  .featurelist {
    text-align: left;

    .list-group-item {
      background: transparent;
      border: none;
      display: flex;
      padding: 0.75rem 1rem;

      &:before {
        content: url("../../../images/green-tick.png");
        position: relative;
        right: 8px;
      }
    }
  }

  .pricebtngrey {
    width: 100%;
    background-color: #e8e4fc;
    color: #6b39fe;
  }
  .pricebtngrey:hover {
    background-color: #6b39fe;
    color: white;
  }

  @media screen and (max-width: $md-device) {
    background: #fff;
    z-index: 1;
    right: 0px;
    left: 0px;
    transform-origin: left bottom;
    transform: skewY(1deg);
    border-radius: 10px;
  }
}

.buttonMobPrice {
  display: none;
  @media screen and (max-width: $md-device) {
    display: block;
  }
}

.loading-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  overflow: hidden;
}

.upcoming-card {
  .para-small {
    font-size: 16px !important;
  }
}

a:hover,
a {
  color: #6b39fe;
}
