$theme-color : #6B39FE;
$theme-footer-dark : #190A33;
$theme-black : #0A0E33;
$theme-black-paragraph : #160248 ;
$theme-light : #F6F7FB ;
$theme-dark : #190A33 ;
$theme-white: #ffffff;
$theme-grey : #F6F7FB;
$theme-heading-para:#C4ABD1;
$theme-button-grey:#E8E4FC;
$theme-faq-grey :#F2F3F7 ;
$theme-grey-text:#6a777d;


//Breakpoints
$sm-device: 576px;
$md-device: 768px;
$lg-device: 992px;
$xl-device: 1200px;