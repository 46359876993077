.btn{
  padding: .75rem 1.5rem !important;
  margin: 30px 0;
  border: none;
  border-radius: 7px;
   font-family:"CircularStd-Medium"; 
  font-size:16px;
  text-transform: capitalize;
  &:focus{
    box-shadow: none;
  }
}

.btn-theme{
  background-color: $theme-color !important;
  color: #ffffff !important;
  &:hover,&:focus{
  
    background-color: #5227d0 !important;
  }
}

.btn-theme-grey{
  background-color: $theme-button-grey;
  margin-left:20px;
  color: $theme-color;
  &:hover,&:focus{
    color:  #ffffff ;
    background-color: $theme-color ;
  }
}

@media(max-width:767px){
  .btn{
    font-size: 14px;
}
}