@font-face {
    font-family: 'CircularStd-BlackItalic';
    src: url('CircularStd-BlackItalic.woff2') format('woff2'),
        url('CircularStd-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd-BoldItalic';
    src: url('CircularStd-BoldItalic.woff2') format('woff2'),
        url('CircularStd-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd-Black';
    src: url('CircularStd-Black.woff2') format('woff2'),
        url('CircularStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd-Book';
    src: url('CircularStd-Book.woff2') format('woff2'),
        url('CircularStd-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd-BookItalic';
    src: url('CircularStd-BookItalic.woff2') format('woff2'),
        url('CircularStd-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd-Bold';
    src: url('CircularStd-Bold.woff2') format('woff2'),
        url('CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd-Medium';
    src: url('CircularStd-Medium.woff2') format('woff2'),
        url('CircularStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd-MediumItalic';
    src: url('CircularStd-MediumItalic.woff2') format('woff2'),
        url('CircularStd-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

