body{
  overflow-x: hidden;
}

.container,.container-fluid{
  margin-left: auto !important;
  margin-right: auto !important;
}


.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}
.navbar-default-black
{
    background-color: #233237;
    padding: 20px 0;
    .navbar-nav{
      .btn-stock
      {
        border: 1px solid #fff;
        color: #fff;
      }
      .btn-fill
      {
        background-color: #ee4a23;
        color: #fff;
      }
    }
}


.App{
overflow-x:hidden;
}
.w-175{
  @media(min-width:1024px){
    width: 175px;
  }
}