#footer-section {
  padding: 120px 0;
  background-color: $theme-footer-dark;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $md-device) {   
    padding: 100px 0 60px 0;
    .footer-logo-section{
      text-align: center !important;
    }
   }
  

  .footer-shape-container {
    position: absolute;
    top: -30%;
  }
  .footer-text {
    color: $theme-white;
  }

  .border-line {
    border-bottom: 1px solid #7a708a80;
    margin: 100px 0 70px;
    @media screen and (max-width: $md-device) {   
      margin: 60px 0;
     }
  }
  .footer-list {
    padding: 0;
    margin: 0;
    list-style: none;
    .list-group-item {
      padding: 5px 0;
      background: transparent;
      a {
        font-size: 14px;
        line-height: 20px;
        font-family: "CircularStd-Book";
        text-decoration: none;
        @media screen and (max-width: $md-device) {   
          font-size: 16px;
         }
      }
      a:hover{
        color: white !important;
      }
    }
  }
  .footer-link {
    
    text-align: left;
    .logo-text {
      padding: 20px 0;
    }

    @media screen and (max-width: $md-device) {   
      .para-small {
        font-size: 18px;
        line-height: 20px;
        margin-top: 15px;
      }
      
     }

   

    .social-footer-links {
      padding-bottom: 40px;
      a {
        padding-right: 10px;
      }
      a:hover{
        color: white !important;
      }
      .onMouse:hover{
        background-color: rgba(247, 243, 243, 0.979);
        border-radius: 20px;
      }
    }
    
    .copy-right-text {
      font-size: 13px;
      line-height: 20px;
      font-family: "CircularStd-Book";
      margin-bottom: 0;
    }
  }
}
.nav-pills {
	.nav-link.active {
		background-color: #fff;
	}
	.show {
		>.nav-link {
			background-color: #fff;
		}
	}
}