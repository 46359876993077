h1{
  font-size:56px;
  font-family:"CircularStd-Black";
  line-height:62px;
  margin-bottom:40px;

  @media screen and (max-width: 1260px) {   
    font-size:50px;
  }
  @media screen and (max-width: 992px) {
    font-size:38px;
    line-height:46px;
    margin-bottom:20px;
  }
  @media (min-width:769px) and(max-width:990px){
    font-size:34px;
    line-height: 42px;
  }
  @media screen and (max-width: $md-device) {
    font-size:38px;
    line-height:46px;
    margin-bottom:20px;
  }
  
}
h2{
  font-size:42px;
  line-height:50px;
  font-family:"CircularStd-Bold";
  margin-bottom:40px;
  @media screen and (max-width: $md-device) {
    font-size:32px;
    line-height:38px;
    margin-bottom:20px;
  }
}

h3{
  font-size:24px;
 line-height:32px;
 font-family:"CircularStd-Bold"; 
}

h4{
  font-size:22px; 
  line-height:33px;
  font-family:"CircularStd-Book"; 
}
h5{
  font-size:17px;
  font-family:"CircularStd-Medium"; 
 
  line-height:24px;
}
h6{
  font-size:12px;
 
  line-height: 20px;
  font-family:"CircularStd-Medium"; 
}
p{
  font-size: 16px;  
  line-height: 21px;
  font-family:"CircularStd-Book"; 
}

.para-small{
  font-size: 14px;  
  line-height: 20px;
  font-family:"CircularStd-Book"; 
}

.sub-text{
  color:$theme-grey-text;
}

.heading-para{
    font-size: 19px;    
    line-height: 26px;
    font-family:"CircularStd-Book"; 
    @media screen and (max-width: $md-device) {
      margin:0
    }
  }
  
  .text-theme{
    color: $theme-color !important;
  }
  .text-headings{
    color: $theme-black  !important;
  }
  .text-white-headings{
    color: $theme-white  !important;
  }

  .text-paragraph{
    color: $theme-black-paragraph !important;
  }
  .text-paragraph-grey{
    color: $theme-heading-para !important;
  }

  .text-heading-paragraph{
    color: $theme-heading-para !important;
  }
  
  .bg-light {
    background-color:$theme-grey !important;
}

.text-bold{
  font-weight:800;
}


@media screen and (max-width: $md-device) {   
  span.d-block {
    display: inline !important;
  }
}
@media screen and (max-width: $md-device) {   
  .logo-text span.d-block {
    display: block !important;
  }
}


