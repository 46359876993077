.Main-header{
    @media screen and (min-width: $md-device) {   
        position: absolute;
        width: 100%;
    }
   .navbar
    .navbar-brand{
        @media screen and (max-width: $md-device) {   
            width: 100px;
    margin-top: 13px;
         } 
    }
    .social_button_mob{
        display: none;
        @media screen and (max-width: $lg-device) {   
            display: block;
            margin-right: 21px;
            margin-left: auto;
         } 
    }
    .navbar{
        background-color: #F6F7FB ;
        @media screen and (min-width: $md-device) {   
          background-color: transparent ;
         } 
        
        z-index: 2;
        .navbar-nav{
            @media screen and (max-width: $md-device) {   
                text-align:left;
             } 

             .nav-link{
                padding-right: 1.5rem !important;
                padding-left: 1.5rem  !important;
                color:$theme-black  !important;
                font-size:16px;
                font-family:"CircularStd-Book"; 
                font-weight: 500;
                transition: 0.5s;
                &:hover,&:focus{
                    color: #6B39FE !important;

                }
            }            
          
        }

      
        .btn-nav-theme{
            @media screen and (max-width: $md-device) {  
                display: none; 
                text-align:left;
                float:left;
                margin-left:20px;
             } 
        }
    }
    
}